<template>
    <v-dialog v-model="dialog" class="">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon v-if="changetab" medium class="mr-2" color="grey">
                    mdi-map
                </v-icon>
                <v-icon v-else-if="item.id" medium class="mr-2" color="grey">
                    mdi-account-edit-outline
                </v-icon>
                <v-icon v-else medium class="mr-2" color="grey" size="35">
                    mdi-account-plus-outline
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" class="text-end">
                        <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab>Dados Pessoais</v-tab>
                    <v-tab v-show="item.id">Equipe</v-tab>
                    <v-tab v-show="item.id">Endereços</v-tab>
                    <v-tab v-show="item.id">Geolicalização</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <DadosPessoais :item-atual="item" @emitpaciente="e_paciente" @change_tab="change_tab" />
                    </v-tab-item>
                    <v-tab-item v-show="item.id">
                        <EquipeMultidisciplinar :paciente="item" />
                    </v-tab-item>
                    <v-tab-item v-show="item.id">
                        <Enderecos :paciente="item" />
                    </v-tab-item>
                    <v-tab-item v-show="item.id">
                        <Geolocalizacao :current-item="item" />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: "Tab",
    props: {
        currentItem: {
            type: Object,
        },
        changetab:{
            type: Number,
        }
    },
    components: {
        DadosPessoais: () => import("./DadosPessoais.vue"),
        EquipeMultidisciplinar: () => import("./EquipeMultisciplinar.vue"),
        Enderecos: () => import("./Enderecos.vue"),
        Geolocalizacao: () => import("./Geolocalizacao.vue"),
    },
    computed:{
    },
    data: () => ({
        dialog: false,
        tab: 0,
        item: {},

    }),
    methods: {
        e_paciente(val) {
            console.log('emit_paciente', val)
            this.item = val
        },
        change_tab() {
            this.tab = 1
        }
    },
    mounted() {
        this.item = this.currentItem
        // faz tab ja abrir na tab 3 = geolocalizacao
        this.tab = this.changetab
    },
}
</script>